.Rule-container {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #636f9a;
    min-width: 320px;
}

.Rule {
    width: 75%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    color: #e9e9e9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
}

.Rule-title {
    font-size: large;
    font-weight: 500;
}