.NavLinks-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.NavLinks-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
}

.NavLinks-item {
    height: 100%;
    padding: 0 1rem;
    letter-spacing: 0.1rem;
    color: #E9E9E9;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    border-top: 2px solid transparent;
    transition: all 220ms ease-in-out;
}

.NavLinks-item:hover {
    border-top: 2px solid #E9E9E9;
    text-decoration: none;
    color: #E9E9E9;
}
