.UserBox {
    width: 75%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 0.8rem;
    color: #E9E9E9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34,41,48,0.6);
}

.UserBox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #222930;
    min-width: 320px;
}

.UserBox-QR-container {
    background-color: white;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    font-size: small;
    padding-top: 10px;
    border-radius: 4px;
    align-self: center;
}

.UserBox h4 {
    font-weight: 500;
    padding-left: 0.5rem;
    margin: 0;
}

.UserBox p {
    margin: 0;
}

.UserBox-catchCode-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0.2rem;
}