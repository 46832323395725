.Leaderboard {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #303e6e;
  font-family: 'Roboto', sans-serif;
}

.Leaderboard-box {
  width: 75%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  color: #e9e9e9;
  background: #303e6e;
  border-radius: 0.2rem;
  padding: 1rem;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.Leaderboard-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Leaderboard td {
  border:none;
  border-collapse: collapse;
}

.Leaderboard-head {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}

.Leaderboard-table-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Leaderboard-user-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}