.USUSgame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    background: #202D58;
}

.pause-text {
    padding-top: 3rem;
    color: #e9e9e9;
    letter-spacing: 0.2rem;
}