.NavBarContainer {
    width: 100%;
    height: 70px;
    background-color: #303e6e;
    box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
}

.Navbar-left-section {
    display: flex;
}

.Navbar-left-section:hover {
    text-decoration: none;
}

.Navbar-middle-section {
    display: flex;
    flex: 2;
    height: 100%;
    justify-content: center;
}

.Navbar-right-section {
    display: flex;
}

.logo-wrapper {
    display: flex;
    align-items: center;
}

.logo-img {
    width: 60px;
    height: 60px;
    padding: 4px 0;
    color: #e9e9e9;
}

.logo-img img {
    width: 100%;
    height: 100%;
}

.logo-text {
    font-size: 16px;
    margin: 0;
    margin-left: 4px;
    color: #e9e9e9;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.25rem;
}

.logo-text span {
    background: #97abfd;
    background: -webkit-linear-gradient(to right, #97abfd 0%, #6C62E6 100%);
    background: -moz-linear-gradient(to right, #97abfd 0%, #6C62E6 100%);
    background: linear-gradient(to right, #97abfd 0%, #6C62E6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Navbar-alive-catched-wrapper {
    margin: auto;
    margin-left: 0.3rem;
    display: flex;
    flex-direction: row;
    font-size: smaller;
    color: #E9E9E9;
    min-width: 80px;
    cursor: initial;
}

.Navbar-alive-catched-wrapper:hover {
    text-decoration: none;
}

.Navbar-catched {
    margin-left: 0.3rem;
}

.Navbar-logout-button {
    border: 0;
    outline: 0;
    padding: 8px 0.8rem;
    border-radius: 20px;
    cursor: pointer;
    letter-spacing: 0.1rem;
    background: linear-gradient( 135deg, hsla(228, 89%, 75%, 1) 26%, hsla(245, 73%, 64%, 1) 91%);
    background: -moz-linear-gradient( 135deg, hsla(228, 89%, 75%, 1) 26%, hsla(245, 73%, 64%, 1) 91%);
    background: -webkit-linear-gradient( 135deg, hsla(228, 89%, 75%, 1) 26%, hsla(245, 73%, 64%, 1) 91%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1);
    color: #e9e9e9;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    transition: all 220ms ease-in-out;
    border: 1px solid transparent;
}

.Navbar-logout-button:hover {
    border: 1px solid #e9e9e9;
}

.Navbar-logout-button:active {
    transform: translateY(1px);
    outline: none;
}

.Navbar-logout-button:focus {
    outline: none;
}