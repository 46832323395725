body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.App {
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  height: 100vh;
  background: #202D58;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media screen and (max-width: 320px){
  .App {
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    height: 130vh;
    width: 320px;
    background: #202D58;
  }
}