.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #202D58;
}

.userList{
    width: 50%;
    text-align: left;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    
}

.headerButton {
    height: 50px;
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: left;
    color: #E9E9E9;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: small;
    text-decoration: none;

}

.headerButton:focus {
    outline: none !important;
    outline-offset: none !important;
}

.headerButton:hover {
    color: #E9E9E9;
    text-decoration: none;
    background-image: linear-gradient(to bottom, transparent 80%, #E9E9E9 81%, transparent 82%);
}


.activeLink {
    color: #E9E9E9;
    text-decoration: none;
    background-image: linear-gradient(to bottom, transparent 80%, #E9E9E9 81%, transparent 82%);
}

.misc {
    color: #e9e9e9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;   
    align-items: center;
}
.rubrik{
    padding: 5px;
    margin-top: 5px;
}

.userManagerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px; 
    max-width: 800px;
}


.userTable {
    width: 100%;
    border: none;
    text-align: left;
    border-style : hidden!important;
    color: #636f9a;
    padding: 15px;
    margin: 10px;
    width: 100%;
    margin-top: 0.1rem;
}

.user-table-button {
    border: none;
    border-radius: 4px;
    outline: none;

    padding: 0.2rem;

  background: linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1 );
  color: #e9e9e9;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.user-table-button:focus {
    outline: none;
    border: none;
}

.user-table-button:active {
    transform: translateY(1px);
}

.userTableWrapper{
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
    padding: 15px;
    margin: 10px;
    width: 100%;
    margin-top: 0.1rem;
}

td {
    border-style : hidden!important;
    color: #e9e9e9;
}

thead {
    border:none;
    color: #e9e9e9;
}

.content {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    background: #202D58;;;
}

.Filereader {
    text-align: left;
    color: #e9e9e9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
    padding: 15px;
    margin: 10px;
    width: 100%;
    border-radius: 0.2rem;
    padding: 1rem;
}


.button-array {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.function-button {
    border: none;
    border-radius: 4px;
    outline: none;
    width: 100%;
    padding: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

  background: linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1 );
  color: #e9e9e9;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.function-button:focus {
    outline: none;
    border: none;
}

.function-button:active {
    transform: translateY(1px);
}

.function-button-paused {
    transition: all 200ms ease-in-out;
    background: rgb(255,73,22);
    background: linear-gradient(90deg, rgba(255,73,22,1) 0%, rgba(250,117,117,0.9931818181818182) 100%);
}

.button-info {
    margin-left: 50px;
}
.newsmanager{
    color: #e9e9e9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
    padding: 15px;
    margin: 10px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}
.rulesManager{
    color: #e9e9e9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
    padding: 15px;
    margin: 10px;
    width: 100%;
    text-align: center;
    max-width: 400px;
}
.endDate{
    display: flex;
    flex-direction: column;
}
.startDate{
   display: flex;
   flex-direction: column;
}
.gameFunctions{
    color: #E9E9E9;
    background: #303e6e;
    border-radius: 0.2rem;
    padding: 1rem;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    font-family: 'Roboto', sans-serif;
    padding: 15px;
    margin: 10px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-form-input {
    border-radius: 4px;
    padding: 0.3rem;
    margin-bottom: 1rem;
    border: none;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
  }
  
  .user-form-input:focus {
    outline: none;
  }

  .Test-adduser {
    display: flex;
    flex-direction: column;
    min-width: 400px;
  }

  .removeUser {
    display: flex;
    flex-direction: column;
    min-width: 400px;
}

.Test-catch-form2 {
    display: flex;
    flex-direction: column;
    min-width: 400px;
}

.addNews {
    display: flex;
    flex-direction: column;
}

.addNews-input {
    border-radius: 4px;
    padding: 0.3rem;
    margin-bottom: 1rem;
    border: none;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.addNews-input-text {
    border-radius: 4px;
    padding: 0.3rem;
    margin-bottom: 1rem;
    border: none;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.addNews-input:focus {
    outline: none;
}

.addNews-input-text:focus {
    outline: none;
}

.addRules {
    display: flex;
    flex-direction: column;
}

.addRules-input {
    border-radius: 4px;
    padding: 0.3rem;
    margin-bottom: 1rem;
    border: none;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.addRules-input:focus {
    outline: none;
}

.addRules-submit {
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 0.2rem;

  background: linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1 );
  color: #e9e9e9;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}
  @media screen and (max-width: 400px){
    .header {
        max-width: 300px;
    }
    .headerButton {
        font-size: xx-small;
        padding: 2px;
    }
    .userTable {
        font-size: x-small;
    }

    .Filereader {
        font-size: small;
        margin-left: 0;
        margin-right: 0;
    }

    .addUser {
        font-size: small;
    }

    .removeUser {
        font-size: small;
    }

    .catchForm {
        font-size: small;      
    }

    .userManagerWrapper {
        max-width: 300px;
    }
    .rulesManager h2 {
        font-size: medium;
    }
    .gameFunctions h2 {
        font-size: medium;
    }

    .userTableWrapper {
        max-width: 325px;
        margin-left: 0;
        margin-right: 0;
    }
    
    .misc {
        margin-left: 0;
        margin-right: 0;
    }
  }

  @media screen and (max-width: 605px) {
      .Test-catch-form2 {
          min-width: 225px;
          max-width: 225px;
      }
      .Test-adduser {
        min-width: 225px;
        max-width: 225px;
      }

    .removeUser {
        min-width: 225px;
        max-width: 225px;
    }

    .content {
        max-width: 400px;
        padding-left: 5%;
        padding-right: 5%;
    }

    .newsmanager h2 {
        font-size: medium;
    }

    .userManagerWrapper {
        max-width: 375px;
    }

    .userTableWrapper {
        max-width: 350px;
    }
  }

  @media screen and (max-width: 850px) {
      .userManagerWrapper {
        max-width: 600px;
      }
  }