.CatchForm {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #636f9a;
  min-width: 320px;
}

.CatchForm-form {
  width: 75%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  color: #e9e9e9;
  background: #303e6e;
  border-radius: 0.2rem;
  padding: 1rem;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.CatchForm-target {
  margin: 0;
  width: 75%;
  max-width: 300px;
  color: #e9e9e9;
  background: #303e6e;
  border-radius: 0.2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.CatchForm-catched {
  margin: 0;
  width: 75%;
  max-width: 300px;
  color: #e9e9e9;
  background: #303e6e;
  border-radius: 0.2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}
.CatchForm-target p {
  font-size: small;
  padding: 0;
  margin: 0.5px;
}

.CatchForm-target h4 {
  padding: 0;
  margin: 0;
  font-size: larger;
  font-weight: 500;
}
.CatchForm-form-input {
  border-radius: 4px;
  padding: 0.3rem;
  margin-bottom: 1rem;
  border: none;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.CatchForm-form-input:focus {
  outline: none;
}

.CatchForm-form-submit {
  border-radius: 4px;
  padding: 0.3rem;

  margin-bottom: 1rem;
  border: 0;
  outline: none;

  background: linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1 );
  color: #e9e9e9;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.CatchForm-form-qr-button {
  border-radius: 4px;
  padding: 0.3rem;
  margin-bottom: 1rem;
  border: 0;
  outline: none;

  background: linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1 );
  color: #e9e9e9;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.CatchForm-form-qr-button:focus {
  outline: none;
  border: none;
}

.CatchForm-form-qr-button:active {
  transform: translateY(1px);
}

.CatchForm-form-submit:focus {
    outline: none;
    border: none;
}

.CatchForm-form-submit:active {
    transform: translateY(1px);
}

.CatchForm-qr-wrapper {
  margin-bottom: 1rem;
}

.CatchForm-qr-wrapper div section section {
  border-radius: 4px;
}