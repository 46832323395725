.MobileNavLinks-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.MobileNavLinks-wrapper {
    background-color: #202D58;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 70px;
    left: 0;
    align-items: flex-end;
    position: absolute;
    z-index: 10;
}

.slide-enter {
    transform: translateX(-100vw);
    opacity: 0;
}
.slide-enter.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms linear 300ms;
}
.slide-enter-done {
    transform: translateX(0);
    opacity: 1;
}
.slide-exit {
    transform: translateX(0);
    opacity: 1;
}
.slide-exit-active {
    transform: translateX(-100vw);
    opacity: 0;
    transition: all 300ms linear 300ms;
}
.slide-exit-done {
    transform: translateX(-100vw);
    opacity: 0;
}


.MobileNavLinks-item {
    width: 50%;
    padding: 1rem 1rem;
    letter-spacing: 0.1rem;
    color: #E9E9E9;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    transition: all 220ms ease-in-out;
    justify-content: flex-end;
}

.MobileNavLinks-item:hover {
    text-decoration: none;
    color: #E9E9E9;
}

.MobileNavbar-logout-button {
    width: 30%;
    border: 0;
    outline: 0;
    margin-right: 0.8rem;
    padding: 0.5rem 0.2rem;
    border-radius: 20px;
    cursor: pointer;
    letter-spacing: 0.1rem;
    background: linear-gradient( 135deg, hsla(228, 89%, 75%, 1) 26%, hsla(245, 73%, 64%, 1) 91%);
    background: -moz-linear-gradient( 135deg, hsla(228, 89%, 75%, 1) 26%, hsla(245, 73%, 64%, 1) 91%);
    background: -webkit-linear-gradient( 135deg, hsla(228, 89%, 75%, 1) 26%, hsla(245, 73%, 64%, 1) 91%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1);
    color: #e9e9e9;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
    transition: all 220ms ease-in-out;
    border: 1px solid transparent;
}

.MobileNavbar-logout-button:hover {
    border: 1px solid #e9e9e9;
}

.MobileNavbar-logout-button:active {
    transform: translateY(1px);
    outline: none;
}

.MobileNavbar-logout-button:focus {
    outline: none;
}

@media screen and (max-width: 325px) {
    .MobileNavbar-logout-button {
        width: 40%;
    }
}