.LoginForm {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222930;
  min-width: 320px;
}

.LoginForm-form {
  width: 75%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  color: #e9e9e9;
  background: #303e6e;
  border-radius: 0.2rem;
  padding: 1rem;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.LoginForm-form-input {
    border-radius: 4px;
    padding: 0.3rem;
    margin-bottom: 1rem;
    border: none;
    box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);
}

.LoginForm-form-input:focus {
    outline: none;
  }

.LoginForm-form-submit {
  border-radius: 4px;
  padding: 0.3rem;
  margin-bottom: 1rem;
  border: 0;
  outline: none;
  color: #e9e9e9;
  box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.6);

  background: linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -moz-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  background: -webkit-linear-gradient(
    135deg,
    hsla(228, 89%, 75%, 1) 26%,
    hsla(245, 73%, 64%, 1) 91%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#849CF8", endColorstr="#6D62E6", GradientType=1 );
}

.LoginForm-form-submit:focus {
    outline: none;
    border: none;
}

.LoginForm-form-submit:active {
    transform: translateY(1px);
}