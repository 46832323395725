.Message {
    margin-top: 1rem;
    color: #4EB1BA;
    background: rgba(78, 177, 186, 0.2);
    border: 1px solid #4EB1BA;
    border-radius: 4px;
    box-shadow: 3px 3px 5px rgba(34,41,48,0.6);
    width: 75%;
    max-width: 300px;
    word-wrap: break-word;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    height: 180px;
    overflow-y: scroll;
}

.Message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #222930;
    min-width: 320px;
    height: 180px;
}

.Message-top-section {
    background: rgba(78, 177, 186, 0.3);
    padding: 0.5rem;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
}

.Message-top-section h5 {
    max-width: 220px;
    text-align: left;
    font-size: medium;
}

.News-text {
    text-align: left;
    padding: 0.3rem;
}

.Message-close {
    display: flex;
    justify-content: flex-end;
}

.close-icon {
    border-radius: 0.6rem;
    margin-top: 5px
}

.close-icon:hover {
    cursor: pointer;
    color:rgba(78, 177, 186, 0.8);
}